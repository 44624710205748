<template>
  <div>
    <b-sidebar
      id="sidebar-Department"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Request</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2" @submit.prevent>
        <b-row class="mt-1">
          <b-col md="12">
            <b-form-group label="Room" invalid-feedback="Room is required.">
              <v-select
                v-model="myObj.roomID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rooms"
                :reduce="(val) => val.id"
                label="roomNo"
                :clearable="false"
                placeholder="select"
              >
                <template #option="opt">
                  <p class="mb-0">{{ getBName(opt.buildingID) }}</p>
                  <p class="mb-0">Room No: {{ opt.roomNo }}</p>
                </template>
                <template #selected-option="opt">
                  <span>{{ getBName(opt.buildingID) }} - {{ opt.roomNo }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Submitted By"
              invalid-feedback="Submitted By is required."
            >
              <v-select
                v-model="myObj.submittedBy"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="staff"
                :reduce="(val) => val.id"
                label="name"
                :clearable="false"
                placeholder="select"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Priority"
              invalid-feedback="Priority is required."
            >
              <v-select
                v-model="myObj.priority"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="priorOptions"
                :clearable="false"
                placeholder="select"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Date Submitted"
              invalid-feedback="Date Submitted is required."
            >
              <flat-pickr
                :config="config"
                v-model="myObj.dateSubmitted"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              label="Date Completed"
              invalid-feedback="Date Completed is required."
            >
              <flat-pickr
                :config="config"
                v-model="myObj.dateCompleted"
                class="form-control"
                placeholder="Select date"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Description">
              <b-form-textarea v-model="myObj.description" rows="6" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              @click="save()"
              :disabled="request"
              block
            >
              <b-spinner v-if="request" small type="grow" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row class="mt-1">
        <b-col xl="2" lg="3" md="4" sm="12" cols="12" v-if="rights.add">
          <b-button
            @click="open(0)"
            block
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Maintenance</span>
          </b-button>
        </b-col>

        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex align-items-center"
          xl="10"
          lg="9"
          md="8"
          sm="12"
          cols="12"
        >
          <b-form-group class="mb-0 w-100">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <!-- <b-button
            variant="outline-primary"
            v-b-tooltip.hover.top
            title="Export"
            class="btn-icon ml-50"
            :disabled="exporting"
            @click="exportData()"
          >
            <b-spinner v-if="exporting" small varaint="primary" />
            <feather-icon v-else icon="UploadIcon" class="" />
          </b-button> -->
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="items"
        :fields="fields"
        :busy="dataLoading"
        show-empty
        responsive
        hover
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(dateSubmitted)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>
        <template #cell(dateCompleted)="data">
          <b-badge variant="light-primary" v-if="data.item.dateCompleted">
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>
        <template #cell(roomID)="data">
          <b-badge variant="light-primary">
            {{ getRoomName(data.value) }}
          </b-badge>
        </template>
        <template #cell(submittedBy)="data">
          <b-badge variant="light-primary">
            {{ getStaffName(data.value) }}
          </b-badge>
        </template>
        <template #cell(priority)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(quantity)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="ml-2">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="">
            <b-button
              v-if="rights.edit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="Edit"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="open(data.item)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-if="data.item.status == 'pending'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              v-b-tooltip.hover.left
              title="Mark Complete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="markComplete(data.item)"
            >
              <feather-icon icon="CheckIcon" class="" />
            </b-button>

            <b-button
              v-if="rights.delete"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              v-b-tooltip.hover.right
              title="Delete"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <!-- <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col> -->
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      rights: {
        view: true,
        add: true,
        edit: true,
        delete: true,
      },
      request: false,
      dataLoading: false,
      exporting: false,
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      visibility: false,
      staff: [],
      fields: [
        { label: "dateSubmitted", key: "dateSubmitted" },
        { label: "room", key: "roomID" },
        { label: "submitted By", key: "submittedBy" },
        { label: "priority", key: "priority" },
        { label: "date Completed", key: "dateCompleted" },
        { label: "status", key: "status" },
        { key: "actions", label: "actions" },
      ],
      priorOptions: ["Low", "High"],
      items: [
        {
          id: 1,
          roomID: 1,
          priority: "Low",
          status: "completed",
          submittedBy: 1,
          dateSubmitted: "2023-12-12",
          dateCompleted: "2023-12-13",
          description: "Chairs fix",
        },
        {
          id: 2,
          roomID: 8,
          priority: "High",
          status: "pending",
          submittedBy: 7,
          dateSubmitted: "2023-10-07",
          dateCompleted: "",
          description: "Ceiling fix",
        },
      ],
      myObj: {
        id: 0,
        roomID: 0,
        priority: "Low",
        status: "pending",
        submittedBy: 0,
        dateSubmitted: new Date().toJSON(),
        dateCompleted: "",
        description: "",
      },
      rooms: [
        {
          id: 8,
          buildingID: 2,
          roomNo: 101,
          floor: 1,
          type: "office",
          capacity: 20,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 9,
          buildingID: 2,
          roomNo: 102,
          floor: 1,
          type: "office",
          capacity: 20,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 10,
          buildingID: 2,
          roomNo: 103,
          floor: 1,
          type: "lab",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 11,
          buildingID: 2,
          roomNo: 201,
          floor: 2,
          type: "classroom",
          capacity: 20,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 12,
          buildingID: 2,
          roomNo: 202,
          floor: 2,
          type: "classroom",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 13,
          buildingID: 2,
          roomNo: 203,
          floor: 2,
          type: "classroom",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 14,
          buildingID: 2,
          roomNo: 204,
          floor: 2,
          type: "classroom",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 15,
          buildingID: 2,
          roomNo: 205,
          floor: 2,
          type: "classroom",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 1,
          buildingID: 1,
          roomNo: 101,
          floor: 1,
          type: "classroom",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 2,
          buildingID: 1,
          roomNo: 102,
          floor: 1,
          type: "classroom",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 3,
          buildingID: 1,
          roomNo: 103,
          floor: 1,
          type: "classroom",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 7,
          buildingID: 1,
          roomNo: 104,
          floor: 1,
          type: "office",
          capacity: 15,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 4,
          buildingID: 1,
          roomNo: 201,
          floor: 2,
          type: "classroom",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 5,
          buildingID: 1,
          roomNo: 202,
          floor: 2,
          type: "classroom",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 6,
          buildingID: 1,
          roomNo: 203,
          floor: 2,
          type: "lab",
          capacity: 30,
          campusID: this.$store.state.userData.cId,
        },
      ],
      buildings: [
        {
          id: 1,
          name: "Block A",
          yearBuilt: 2020,
          totalFloors: 3,
          campusID: this.$store.state.userData.cId,
        },
        {
          id: 2,
          name: "Block B",
          yearBuilt: 2020,
          totalFloors: 1,
          campusID: this.$store.state.userData.cId,
        },
      ],
    };
  },
  computed: {
    // filters() {
    //   return this.items.filter((pro) => {
    //     return pro.roomNo
    //       .toString()
    //       .toLowerCase()
    //       .match(this.searchQuery.toLowerCase());
    //   });
    // },
  },
  created() {
    this.LoadStaff();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getStaffName(id) {
      let obj = this.staff.find((el) => el.id == id);
      return obj ? obj.name : "M.Ali";
    },
    getBName(id) {
      let obj = this.buildings.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    getRoomName(id) {
      let obj = this.rooms.find((el) => el.id == id);
      return obj ? obj.roomNo : "";
    },

    open(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          roomID: 0,
          priority: "Low",
          status: "pending",
          submittedBy: 0,
          dateSubmitted: new Date().toJSON(),
          dateCompleted: "",
          description: "",
        };
      } else {
        this.myObj = { ...id };
      }
      this.visibility = true;
    },
    save() {
      this.request = true;
      if (this.myObj.dateCompleted) this.myObj.status = "completed";
      if (this.myObj.id == 0) {
        this.myObj.id = Math.max(...this.items.map((obj) => obj.id), 0) + 1;
        this.items.push(this.myObj);
      } else {
        let ind = this.items.findIndex((elem) => elem.id == this.myObj.id);
        this.items.splice(ind, 1, this.myObj);
      }
      this.request = false;
      this.visibility = false;
      this.$bvToast.toast("Request saved successfully!", {
        title: "Success!",
        variant: "success",
        toaster: "b-toaster-top-right",
        solid: true,
      });
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        this.items = this.items.filter((el) => el.id != id);
        this.$bvToast.toast("Request deleted", {
          title: "Success!",
          variant: "success",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      }
    },
    async markComplete(item) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      });

      if (result.isConfirmed) {
        item.status = "completed";
        this.$bvToast.toast("Request completed", {
          title: "Success!",
          variant: "success",
          toaster: "b-toaster-top-right",
          solid: true,
        });
      }
    },

    async LoadStaff() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "staff/filter?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=0&status=present",
        token: this.$store.state.userData.token,
      };
      this.staff = await this.get(obj);

      this.dataLoading = false;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
